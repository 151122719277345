import React, { ReactElement, useEffect, useState } from 'react';

import { styled } from '@linaria/react';

const ScrollUp = styled.div<{ isVisible: boolean; }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #315efb;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.2s;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    background-image: url('./images/up.svg');
  }
  &:hover {
    background-color: #2c54e2;
  }
  &:active {
    background-color: #274bc8;
  }
`;

const ScrollUpComponent = (): ReactElement => {
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [marginBottom, setMarginBottom] = useState<number>(0);

  useEffect(() => {

    const setDimensionsAndPosition = () => {
      const footerElement = document.querySelector('footer');
      setScrollHeight(
        document.documentElement.scrollHeight
      );
      setViewportHeight(
        Math.max(document.documentElement.clientHeight, window.innerHeight || 0 )
      );
      setFooterHeight(
        (footerElement && footerElement.getBoundingClientRect().height) || 0
      );
      setPosition();
    };
  
    const setPosition = () => {
      const fixedBottomThreshold = footerHeight;
      const isFixed = scrollHeight - window.scrollY - viewportHeight > fixedBottomThreshold;
      setIsVisible(
        window.scrollY * 1.5 > viewportHeight
      );
      setMarginBottom(
        isFixed
          ? 0
          : fixedBottomThreshold - (scrollHeight - window.scrollY - viewportHeight)
      );
  
    };

    window.addEventListener('resize', setDimensionsAndPosition);
    window.addEventListener('scroll', setPosition);
    setDimensionsAndPosition();
    const rerenderInterval = setInterval(setDimensionsAndPosition, 200);

    return () => {
      window.removeEventListener('resize', setDimensionsAndPosition);
      window.removeEventListener('scroll', setPosition);
      clearInterval(rerenderInterval);
    };

  }, [scrollHeight, viewportHeight, footerHeight]);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <ScrollUp
      onClick={scrollUp}
      isVisible={isVisible}
      style={{
        marginBottom: marginBottom,
      }}
    />
  );
};

export default ScrollUpComponent;
